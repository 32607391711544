<script context="module" lang="ts">
	import * as Sentry from '@sentry/svelte';
	import routeGuard from '$lib/routes/route-guard';
	import type { LoadInput, LoadOutput } from '@sveltejs/kit/types/internal';
	import { client } from 'src/lib/apollo/client-provider';
	import { setClient } from 'svelte-apollo';
	import userStore from 'src/stores/user';
	import { loading } from 'src/stores/loading';

	export async function load(input: LoadInput): Promise<LoadOutput> {
		// automatically triggers silent auth
		loading.set(true);
		setClient(client);
		const loadOutput = await routeGuard(input);
		return loadOutput;
	}

	if (browser)
		// Initialize the Sentry SDK here
		Sentry.init({
			dsn: 'https://7c73dd529ba04a2b8203a9d7dabad6dd@o750173.ingest.sentry.io/4503926405398528',
			integrations: [new BrowserTracing()],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0
		});
</script>

<script>
	import '../app.css';
	import { afterNavigate, beforeNavigate, invalidate } from '$app/navigation';
	import Spinner from 'src/components/common/spinner.svelte';
	import { BrowserTracing } from '@sentry/tracing';
	import { browser } from '$app/env';

	let reload = false;

	beforeNavigate(async (nav) => {
		loading.set(true);
		if (!(await userStore.isAuthenticated())) {
			reload = true;
		}
	});

	afterNavigate(() => {
		if (reload) location.reload();
		loading.set(false);
	});
</script>

<div class="bg-[#f4f5f5] h-full min-h-screen relative" data-sveltekit-prefetch>
	{#if !$loading}
		<slot />
	{:else}
		<div class="flex w-full h-screen justify-center items-center">
			<Spinner color="neutral-500" size="12rem" />
		</div>
	{/if}
</div>
